/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-yzul2x --center --parallax pb--80 pt--80" name={"intro"} lightbox={false} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/c14eb73fd38544d5a31183c767981e0b_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pl--40 pr--40 pt--40 flex--center" animS={"3"} style={{"maxWidth":1280,"backgroundColor":"var(--color-dominant)"}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5 mb--40 mt--40" anim={"7"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--102" style={{"maxWidth":""}} content={"Wesołych Świąt"}>
              </Title>

              <Subtitle className="subtitle-box swpf--uppercase" content={"i szczęśliwego"}>
              </Subtitle>

              <Title className="title-box fs--102 mt--10" style={{"maxWidth":""}} content={"Nowego Roku"}>
              </Title>

              <Subtitle className="subtitle-box swpf--uppercase" content={"życzy<br>Marek & Aneta"}>
              </Subtitle>

              <Text className="text-box mt--50" content={"Utworzone przez <a style=\"color: inherit\" href=\"https://saywebpage.com/cs/pf2021/\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}